import React, { useCallback, useEffect, useState } from 'react'
import { SxProps, Stack, Select, MenuItem, Typography, Grid, CircularProgress } from '@mui/material'
import { TextFieled } from 'components/common/TextFieled'
import { SideBarMarkerIcon, sideBarSvgToBase64DataURL } from 'components/map/marker/SideBarMarkerIcon'
import { useApi } from 'hooks/tif/useApi'
import { GroupUsers, Groups } from 'hooks/tif/usePoolingData'
import { SendPushNotificationRequest, SendPushNotificationResponse } from 'tif/api'
import { Button } from '../common/Button'
import { Textarea } from '../common/Textarea'
interface Props {
	children?: React.ReactNode
	title?: string
	style?: SxProps
	userDocumentId?: string
	groupDocumentId?: string
	groups: Groups
	groupUsers: GroupUsers
	setSettings?: () => void
	onError?: (message?: string) => void
}

type SendLevel = 'all' | 'group' | 'user'

type Phase = 'setting' | 'confirm' | 'sending' | 'success' | 'error'

interface Inputs {
	sendLevel: SendLevel
	userDocumentId: string
	groupDocumentId: string
	tite: string
	body: string
}

const defaultInputs: Inputs = {
	sendLevel: 'group',
	userDocumentId: 'default',
	groupDocumentId: 'default',
	tite: '',
	body: ''
}

export const PushMessageController: React.FC<Props> = (props) => {
	const [sendLevel, setSendLevel] = useState<SendLevel>(defaultInputs.sendLevel)
	const [title, setTitle] = useState<string>(defaultInputs.body)
	const [body, setBody] = useState<string>(defaultInputs.tite)
	const [userDocumentId, setUserDocumentId] = useState<string>(defaultInputs.userDocumentId)
	const [groupDocumentId, setGroupDocumentId] = useState<string>(defaultInputs.groupDocumentId)
	const [phase, setPhase] = useState<Phase>('setting')
	const { post, error, setError } = useApi()
	const [result, setResult] = useState<SendPushNotificationResponse>()

	useEffect(() => {
		if (!props.groupDocumentId) return
		if (!props.userDocumentId) return
		setSendLevel('user')
		setGroupDocumentId(props.groupDocumentId)
		setUserDocumentId(props.userDocumentId)
	}, [props.userDocumentId, props.groupDocumentId])

	useEffect(() => {
		if (!error) return
		setPhase('error')
	}, [error])

	const sendPushMessage = useCallback(async () => {
		setPhase('sending')
		const result = await post<SendPushNotificationRequest, SendPushNotificationResponse>('/sendPushNotification', {
			title,
			body,
			userDocumentId: userDocumentId === defaultInputs.userDocumentId ? undefined : userDocumentId,
			groupDocumentId: groupDocumentId === defaultInputs.groupDocumentId ? undefined : groupDocumentId
		})
		setResult(result)
		setPhase('success')
	}, [sendLevel, userDocumentId, groupDocumentId, body, title, props.onError])

	const onClick = useCallback(() => {
		let errorMessage: string | undefined

		if (!sendLevel.match(/^all$|^group$|^user$/)) {
			errorMessage = '不正な送信レベルです'
		}

		if (typeof title !== 'string' || typeof body !== 'string') {
			errorMessage = 'タイトルまたはメッセージは文字列である必要があります'
		}

		if (title === '' || body === '') {
			errorMessage = 'タイトルまたはメッセージに空文字は使用できません'
		}

		if (sendLevel.match(/^group$/) && (!groupDocumentId || groupDocumentId === 'default')) {
			errorMessage = 'グループのドキュメントIDが取得できませんでした'
		}

		if (
			sendLevel.match(/^user$/) &&
			(!groupDocumentId || groupDocumentId === 'default' || !userDocumentId || userDocumentId === 'default')
		) {
			errorMessage = 'ドキュメントIDが取得できませんでした'
		}

		if (props.onError && errorMessage) {
			props.onError(errorMessage)
		} else {
			setPhase('confirm')
		}
	}, [sendLevel, userDocumentId, groupDocumentId, body, title, props.onError])

	if (phase === 'sending') {
		return (
			<Stack
				component={'form'}
				noValidate
				padding={2}
				justifyContent={'center'}
				alignItems={'center'}
				spacing={1}
				sx={{
					...props.style
				}}
			>
				<CircularProgress />
			</Stack>
		)
	}

	if (phase === 'success') {
		return (
			<Stack
				component={'form'}
				noValidate
				padding={2}
				justifyContent={'center'}
				alignItems={'center'}
				spacing={1}
				sx={{
					...props.style
				}}
			>
				<Typography>送信完了</Typography>
				<Typography fontSize={'11px'}>
					送信成功:{' '}
					{(result?.result.maxItemsCount ?? 0) - (result?.result.failedSendPushNotificationCount ?? 0)}/
					{result?.result.maxItemsCount}
				</Typography>
				<Typography fontSize={'11px'}>
					DB更新成功: {(result?.result.maxItemsCount ?? 0) - (result?.result.failedUpdateFirestoreCount ?? 0)}
					/ {result?.result.maxItemsCount}
				</Typography>
				<Button
					variant="contained"
					onClick={() => {
						setError(undefined)
						setBody(defaultInputs.body)
						setTitle(defaultInputs.tite)
						setGroupDocumentId(defaultInputs.groupDocumentId)
						setUserDocumentId(defaultInputs.userDocumentId)
						setPhase('setting')
					}}
					style={{
						fontSize: '11px'
					}}
					color="primary"
				>
					この画面を閉じる
				</Button>
			</Stack>
		)
	}

	if (phase === 'error') {
		return (
			<Stack
				component={'form'}
				noValidate
				padding={2}
				justifyContent={'center'}
				alignItems={'center'}
				spacing={1}
				sx={{
					...props.style
				}}
			>
				<Typography color={'error'}>エラー</Typography>
				<Button
					variant="contained"
					onClick={() => {
						setError(undefined)
						setBody(defaultInputs.body)
						setTitle(defaultInputs.tite)
						setGroupDocumentId(defaultInputs.groupDocumentId)
						setUserDocumentId(defaultInputs.userDocumentId)
						setPhase('setting')
					}}
					style={{
						fontSize: '11px'
					}}
					color="primary"
				>
					この画面を閉じる
				</Button>
			</Stack>
		)
	}

	return (
		<Stack
			component={'form'}
			noValidate
			padding={2}
			justifyContent={'center'}
			alignItems={'center'}
			spacing={1}
			sx={{
				...props.style
			}}
		>
			<Grid
				container
				alignItems={'center'}
			>
				<Grid
					item
					xs={4}
				>
					<Typography>送信レベル</Typography>
				</Grid>
				<Grid
					item
					xs={8}
				>
					<Select
						size="small"
						sx={{ width: '100%' }}
						value={sendLevel}
						disabled={phase === 'confirm'}
						onChange={(e) => {
							setSendLevel(e.target.value as SendLevel)
							setGroupDocumentId(defaultInputs.groupDocumentId)
							setUserDocumentId(defaultInputs.userDocumentId)
						}}
					>
						<MenuItem value={'all'}>全員に送信</MenuItem>
						<MenuItem value={'group'}>グループに送信</MenuItem>
						<MenuItem value={'user'}>個別に送信</MenuItem>
					</Select>
				</Grid>
			</Grid>
			{(sendLevel === 'group' || sendLevel === 'user') && (
				<Grid
					container
					alignItems={'center'}
				>
					<Grid
						item
						xs={4}
					>
						<Typography>グループ名</Typography>
					</Grid>
					<Grid
						item
						xs={8}
					>
						<Select
							size="small"
							sx={{ width: '100%' }}
							value={groupDocumentId}
							disabled={phase === 'confirm'}
							onChange={(e) => {
								setGroupDocumentId(e.target.value)
								setUserDocumentId(defaultInputs.userDocumentId)
							}}
						>
							<MenuItem value={defaultInputs.groupDocumentId}>選択</MenuItem>
							{Object.keys(props.groups).map((id) => {
								const group = props.groups[id]
								const color = sideBarSvgToBase64DataURL(
									SideBarMarkerIcon(group.color, group.color),
									1
								).url
								return (
									<MenuItem
										value={id}
										key={id}
									>
										<Stack
											direction={'row'}
											alignItems={'center'}
											justifyContent={'start'}
										>
											<img
												src={color}
												height="20"
												style={{ marginRight: '5px' }}
											/>
											{group.displayName}
										</Stack>
									</MenuItem>
								)
							})}
						</Select>
					</Grid>
				</Grid>
			)}
			{sendLevel === 'user' && groupDocumentId !== 'default' && (
				<Grid
					container
					alignItems={'center'}
				>
					<Grid
						item
						xs={4}
					>
						<Typography>ユーザー名</Typography>
					</Grid>
					<Grid
						item
						xs={8}
					>
						<Select
							size="small"
							disabled={phase === 'confirm'}
							sx={{ width: '100%' }}
							value={userDocumentId}
							onChange={(e) => {
								setUserDocumentId(e.target.value)
							}}
						>
							<MenuItem value={defaultInputs.userDocumentId}>選択</MenuItem>
							{props.groupUsers[groupDocumentId] &&
								Object.keys(props.groupUsers[groupDocumentId]).map((id) => {
									const user = props.groupUsers[groupDocumentId][id]
									return (
										<MenuItem
											key={id}
											value={id}
										>
											{user.displayName ?? 'unknown'}
										</MenuItem>
									)
								})}
						</Select>
					</Grid>
				</Grid>
			)}
			<Stack
				spacing={3}
				width={1}
			>
				<Grid
					container
					alignItems={'center'}
				>
					<Grid
						item
						xs={12}
					>
						<Typography>タイトル</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextFieled
							disabled={phase === 'confirm'}
							value={title}
							size="small"
							onChange={(e) => {
								setTitle(e.target.value)
							}}
							sx={{
								width: '100%',
								height: '1rem'
							}}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					alignItems={'center'}
				>
					<Grid
						item
						xs={12}
					>
						<Typography>メッセージ</Typography>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Textarea
							value={body}
							minRows={6}
							maxRows={12}
							disabled={phase === 'confirm'}
							onChange={(e) => {
								setBody(e.target.value)
							}}
							style={{
								resize: 'none'
							}}
						/>
					</Grid>
				</Grid>
			</Stack>
			<Stack
				direction={'row'}
				spacing={2}
			>
				{phase === 'setting' && (
					<Button
						variant="contained"
						onClick={onClick}
						style={{
							fontSize: '11px'
						}}
						color="primary"
					>
						メッセージを確認する
					</Button>
				)}
				{phase === 'confirm' && (
					<Button
						variant="outlined"
						onClick={() => {
							setPhase('setting')
						}}
						style={{
							fontSize: '11px'
						}}
						color="primary"
					>
						内容を変更する
					</Button>
				)}
				{phase === 'confirm' && (
					<Button
						variant="contained"
						onClick={sendPushMessage}
						style={{
							fontSize: '11px'
						}}
						color="primary"
					>
						メッセージを確認する
					</Button>
				)}
			</Stack>
		</Stack>
	)
}
