import { useEffect, useState } from 'react'
import { firebaseConfig } from 'configs/google/firebase/firebaseConfig'
import { FirebaseApp, getApp, getApps, initializeApp } from 'firebase/app'
import { useLogger } from '../../useLogger'

interface FirebaseAppHook {
	app?: FirebaseApp
	error?: string
}

/**
 * Firebaseサービスを１つ使用する
 */
export const useSingleFirebaseApp = (): FirebaseAppHook => {
	const [app, setApp] = useState<FirebaseApp>()
	const [error, setError] = useState<string>()
	const logger = useLogger()

	useEffect(() => {
		try {
			if (getApps().length === 0) {
				const app = initializeApp(firebaseConfig)
				setApp(app)
				logger.debug('useSingleFirebaseApp', 'Firebaseアプリケーションを作成しました', app)
			} else {
				const app = getApp()
				setApp(app)
				logger.debug('useSingleFirebaseApp', '作成されたアプリケーションを返しました。', app)
			}
		} catch (error) {
			logger.error('useSingleFirebaseApp', 'Firebaseアプリケーションの作成に失敗しました', error)
			setError('Firebaseアプリケーションの作成に失敗しました')
		}
	}, [])
	return { app, error }
}
