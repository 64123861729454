import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * 指定したURLクエリーのデータを使用する
 * @param query URLクエリー
 * @returns
 */
export const useQuery = <T extends string>(query: string): T | undefined => {
	const search = useLocation().search
	return useMemo(() => {
		const data = new URLSearchParams(search).get(query) as T | null
		return data ?? undefined
	}, [query, search])
}
