import React, { CSSProperties, JSXElementConstructor, ReactElement, useCallback, useEffect, useState } from 'react'
import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { Marker as TifMarker, Route, LatLng } from 'tif/map'
import { GoalPannel } from './marker/GoalPannel'
import { MarkerIcon, svgToBase64DataURL } from './marker/MarkerIcon'
import { StartPannel } from './marker/StartPannel'

interface Props {
	apiKey: string
	options: google.maps.MapOptions
	style?: CSSProperties
	statusRender?: (status: Status) => ReactElement<any, string | JSXElementConstructor<any>>
	markers: TifMarker[]
	routes: Route[]
	radius: number
	start: LatLng
	goal: LatLng
	onClickMarker?: (groupDocumentId: string, userDocumentId: string) => void
}

const defaultStyle: CSSProperties = {
	position: 'absolute',
	left: 0,
	top: 0,
	margin: 0,
	padding: 0,
	width: '100%',
	height: '100%'
}

/**
 * ２点間の距離を直径に持つ円の半径を求める
 * @param mk1 地点a
 * @param mk2 地点b
 * @returns
 */
const getCircleRadiusFrom2p = (mk1: LatLng, mk2: LatLng): number => {
	const R = 3958.8 // 地球の半径(Miles)
	const rlat1 = mk1.lat * (Math.PI / 180)
	const rlat2 = mk2.lat * (Math.PI / 180)
	const difflat = rlat2 - rlat1
	const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180)

	const d =
		2 *
		R *
		Math.asin(
			Math.sqrt(
				Math.sin(difflat / 2) * Math.sin(difflat / 2) +
					Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
			)
		)
	return d * (R / 6)
}

type Marker = google.maps.Marker | undefined

type Polyline = google.maps.Polyline | undefined

export const TifMap: React.FC<Props> = (props) => {
	const [map, setMap] = useState<google.maps.Map>()
	const [markers, setMarkers] = useState<Record<string, Marker>>({})
	const [element, setElement] = useState<HTMLDivElement>()
	const [lines, setLines] = useState<Record<string, Polyline>>({})
	const [goalMarker, setGoalMarker] = useState<Marker>()
	const [startMarker, setStartMarker] = useState<Marker>()

	/**
	 * エレメントの参照を保持
	 */
	const ref = useCallback((_element: HTMLDivElement | null): void => {
		if (!_element) return
		setElement(_element)
	}, [])

	/**
	 * マーカーのクリア
	 */
	const clearMarker = useCallback(() => {
		Object.keys(markers).forEach((id) => {
			markers[id]?.setMap(null)
		})
		setMarkers({})
	}, [markers])

	/**
	 * ルート（線図形）のクリア
	 */
	const clearLinesAndStartGoalMarker = useCallback(() => {
		Object.keys(lines).forEach((id) => {
			lines[id]?.setMap(null)
		})
	}, [lines, startMarker, goalMarker])

	/**
	 * マーカーオプションを作成
	 * @param marker マーカー
	 * @param icon アイコン
	 * @returns
	 */
	const createMarkerOption = (
		marker: TifMarker,
		icon: {
			url: string
			scaledSize: google.maps.Size
		}
	): google.maps.MarkerOptions => {
		let zIndex: number = 10

		if (marker.kind === 'artist') {
			icon.scaledSize.width += 5
			icon.scaledSize.height += 5
			zIndex = 50
		}

		const offsetWidth = 1
		const offsetHeight = 2

		const pos = new google.maps.Point(
			icon.scaledSize.width / 2 - offsetWidth,
			icon.scaledSize.height / 2 - offsetHeight
		)

		return {
			title: marker.id,
			label: {
				color: 'white',
				text: marker.label ?? ''
			},
			position: {
				lat: marker.lat,
				lng: marker.lng
			},
			visible: marker.visible,
			icon: {
				url: icon.url,
				scaledSize: icon.scaledSize,
				labelOrigin: pos
			},
			zIndex
		}
	}

	/**
	 * マップの生成
	 */
	useEffect(() => {
		if (!element) return
		const map = new window.google.maps.Map(element, {
			...props.options,
			disableDefaultUI: true,
			mapTypeControl: true,
			zoomControl: true,
			zoomControlOptions: {
				position: window.google.maps.ControlPosition.LEFT_BOTTOM
			},
			streetViewControl: true,
			streetViewControlOptions: {
				position: window.google.maps.ControlPosition.LEFT_BOTTOM
			}
		})

		if (props.options.center) {
			const p1 = {
				lat: (props.options.center.lat as number) - props.radius,
				lng: (props.options.center.lng as number) - props.radius
			}
			const p2 = {
				lat: (props.options.center.lat as number) + props.radius,
				lng: (props.options.center.lng as number) + props.radius
			}

			const r = getCircleRadiusFrom2p(p1, p2)

			new window.google.maps.Circle({
				center: props.options.center,
				radius: r,
				strokeColor: '#EE4D1D',
				strokeOpacity: 1,
				strokeWeight: 2,
				fillColor: '#FF0000',
				fillOpacity: 0,
				map
			})

			new window.google.maps.Circle({
				center: props.options.center,
				radius: r - 30,
				strokeColor: '#FB7D10',
				strokeOpacity: 1,
				strokeWeight: 2,
				fillColor: '#FF0000',
				fillOpacity: 0,
				map
			})
		}

		const startIcon = svgToBase64DataURL(StartPannel(), 40)
		const goarlIcon = svgToBase64DataURL(GoalPannel(), 40)

		setStartMarker(
			new google.maps.Marker({
				position: props.start,
				icon: {
					url: startIcon.url,
					scaledSize: startIcon.scaledSize
				},
				zIndex: 100,
				map
			})
		)

		setGoalMarker(
			new google.maps.Marker({
				position: props.goal,
				icon: {
					url: goarlIcon.url,
					scaledSize: goarlIcon.scaledSize
				},
				zIndex: 100,
				map
			})
		)

		setMap(map)
	}, [element])

	/**
	 * ルート（線図形のクリア）の生成
	 */
	useEffect(() => {
		if (props.routes.length < 1) return
		if (!map) return
		clearLinesAndStartGoalMarker()

		const _lines: Record<string, Polyline> = {}
		props.routes.forEach((route, id) => {
			const path: LatLng[] = [route.startGeo, ...route.waypoints, route.endGeo]
			const line = new google.maps.Polyline({
				path,
				map,
				strokeColor: route.strokeColor,
				strokeOpacity: route.strokeOpacity,
				strokeWeight: route.strokeWeight,
				visible: route.visible
			})
			_lines[id] = line
		})

		setLines({ ..._lines })
	}, [map, props.routes?.length])

	/**
	 * マーカーの生成
	 */
	useEffect(() => {
		if (!map) return
		if (props.markers.length < 1) return
		clearMarker()

		const _markers: Record<string, Marker> = {}

		props.markers.forEach((marker) => {
			const icon = svgToBase64DataURL(MarkerIcon(marker.color, marker.strokeColor), 32)
			const _marker = new google.maps.Marker({
				...createMarkerOption(marker, icon),
				map
			})
			_markers[marker.id] = _marker

			google.maps.event.addListener(_marker, 'click', () => {
				if (props.onClickMarker) props.onClickMarker(marker.groupId, marker.id)
			})
		})

		setMarkers({ ..._markers })
	}, [map, props.markers?.length])

	/**
	 * ルートの更新
	 */
	useEffect(() => {
		if (props.routes.length < 1) return
		if (!map) return
		props.routes.forEach((_route, id) => {
			const line = lines[id]
			if (!line) return
			const path: LatLng[] = [_route.startGeo, ..._route.waypoints, _route.endGeo]
			line.setOptions({
				path,
				strokeColor: _route.strokeColor,
				strokeOpacity: _route.strokeOpacity,
				strokeWeight: _route.strokeWeight,
				visible: _route.visible
			})
		})
	}, [map, props.routes])

	/**
	 * マーカーの更新
	 */
	useEffect(() => {
		if (!map) return
		if (props.markers.length < 1) return

		props.markers.forEach((_marker) => {
			const marker = markers[_marker.id]
			if (!marker) return
			const icon = svgToBase64DataURL(MarkerIcon(_marker.color, _marker.strokeColor), 32)
			marker.setOptions(createMarkerOption(_marker, icon))
		})
	}, [markers, props.markers])

	return (
		<>
			<Wrapper
				apiKey={props.apiKey}
				render={props.statusRender}
			>
				<div
					ref={ref}
					id="map"
					style={{
						...defaultStyle,
						...props.style
					}}
				/>
			</Wrapper>
		</>
	)
}
