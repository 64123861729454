import pino, { Logger } from 'pino'

/**
 * pinoロガーを使用する
 * @returns pinoロガー関数
 */
export const useLogger = (): Logger => {
	return pino({
		level: import.meta.env.VITE_LOGGING_LEVEL || 'debug'
	})
}
