import React, { useCallback, useEffect, useState } from 'react'
import { SxProps, FormGroup, FormControlLabel, Checkbox, Stack, Typography } from '@mui/material'
import { SideBarMarkerIcon, sideBarSvgToBase64DataURL } from 'components/map/marker/SideBarMarkerIcon'
import { Groups } from 'hooks/tif/usePoolingData'
interface Props {
	children?: React.ReactNode
	title?: string
	style?: SxProps
	groups: Groups
	onChangeVisibles?: (groupVisibles: Record<string, boolean>) => void
}
export const GroupMarkerController: React.FC<Props> = (props) => {
	const [visibles, setVisibles] = useState<Record<string, boolean>>({})

	const onClickCheckbox = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, visible: boolean, groupId: string): void => {
			visibles[groupId] = !visible
			setVisibles({ ...visibles })
		},
		[visibles]
	)

	useEffect(() => {
		if (!props.groups) return
		const gv: Record<string, boolean> = {}

		Object.keys(props.groups).forEach((groupId) => {
			gv[groupId] = true
		})
		setVisibles(gv)
	}, [props.groups])

	useEffect(() => {
		if (props.onChangeVisibles) props.onChangeVisibles(visibles)
	}, [visibles])

	return (
		<Stack padding={2}>
			<FormGroup
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					gridTemplateRows: 'auto'
				}}
			>
				{Object.keys(props.groups).map((id) => {
					const group = props.groups[id]
					const color = sideBarSvgToBase64DataURL(SideBarMarkerIcon(group.color, group.color), 1).url
					return (
						<FormControlLabel
							key={id}
							control={
								<>
									<Checkbox
										checked={visibles[id] ?? false}
										onClick={(event) => {
											onClickCheckbox(event, visibles[id], id)
										}}
										sx={{
											'&.Mui-checked': {
												color: group.color
											}
										}}
									/>
									<img
										src={color}
										height="20"
										style={{ marginRight: '5px' }}
									/>
								</>
							}
							label={<Typography fontSize={'12px'}>{group.displayName}</Typography>}
						/>
					)
				})}
			</FormGroup>
		</Stack>
	)
}
