import { Stack, SxProps } from '@mui/material'
interface Props {
	children?: React.ReactNode
	style?: SxProps
}

export const SideBar: React.FC<Props> = (props) => {
	return (
		<Stack
			spacing={3}
			sx={{
				...props.style
			}}
			direction={'column'}
		>
			{props.children}
		</Stack>
	)
}
