import React from 'react'
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material'
import { styled } from '@mui/system'

interface Props extends TextareaAutosizeProps {}

const StyledTextarea = styled(TextareaAutosize)(
	({ theme }) => `
    border-radius: 5px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.0;
    padding: 12px;
    width: 90%;
  `
)

export const Textarea: React.FC<Props> = (props) => {
	return <StyledTextarea {...props}></StyledTextarea>
}
