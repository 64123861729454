import React from 'react'
import { Button as MuiButton, ButtonTypeMap } from '@mui/material'
import { OverrideProps } from '@mui/material/OverridableComponent'

interface Props extends OverrideProps<ButtonTypeMap, 'button'> {}

export const Button: React.FC<Props> = (props) => {
	return (
		<MuiButton
			{...props}
			sx={{
				':hover': {
					boxShadow: 'none'
				},
				boxShadow: 'none',
				transition: 'all 1s ease-out',
				...props.sx
			}}
		>
			{props.children}
		</MuiButton>
	)
}
