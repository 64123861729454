import { Stack, SxProps, Typography } from '@mui/material'

interface Props {
	children?: React.ReactNode
	title?: string
	style?: SxProps
}

export const WindowBox: React.FC<Props> = (props) => {
	return (
		<Stack
			justifyContent={'center'}
			alignItems={'center'}
			width={1}
			sx={{
				background: '#FFF'
			}}
			borderRadius={'10px'}
			direction={'column'}
		>
			<Stack
				width={1}
				paddingY={'8px'}
				alignItems={'center'}
				borderRadius={'10px 10px 0 0'}
				sx={{
					background: 'lightgray 50%'
				}}
			>
				<Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{props.title}</Typography>
			</Stack>
			<Stack
				width={1}
				sx={{
					...props.style
				}}
			>
				{props.children}
			</Stack>
		</Stack>
	)
}
