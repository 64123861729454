import { useEffect, useRef, useState } from 'react'
import { Alert, Slide, Typography } from '@mui/material'

interface Props {
	alertMessae?: string
	resetAlertMessage?: () => void
}

export const AlertMessage: React.FC<Props> = (props) => {
	const [isShow, setShow] = useState<boolean>(false)
	const [message, setMessage] = useState<string>()
	const timer = useRef<NodeJS.Timer>()

	useEffect(() => {
		if (!props.alertMessae) {
			setMessage(undefined)
			return
		}
		if (timer.current) {
			clearTimeout(timer.current)
			timer.current = undefined
		}
		setMessage(props.alertMessae)
		setShow(true)
		timer.current = setTimeout(() => {
			setShow(false)
			setMessage(undefined)
			if (props.resetAlertMessage) props.resetAlertMessage()
		}, 5000)
	}, [props.alertMessae])
	return (
		<Slide
			direction="left"
			in={isShow}
			mountOnEnter
			unmountOnExit
		>
			<Alert
				variant="filled"
				severity="error"
				sx={{
					position: 'absolute',
					width: '40vw',
					minWidth: '350px',
					right: 0,
					top: 0
				}}
			>
				<Typography fontSize={'14px'}>{message}</Typography>
			</Alert>
		</Slide>
	)
}
