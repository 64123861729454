import { useContext, useState } from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { SideBar } from 'components/common/SideBar'
import { WindowBox } from 'components/common/WindowBox'
import { AlertMessage } from 'components/feature/AlertMessage'
import { GroupMarkerController } from 'components/feature/GroupMarkerController'
import { PushMessageController } from 'components/feature/PushMessageController'
import { RouteListController } from 'components/feature/RouteListController'
import { TifMap } from 'components/map/TifMap'
import { useDashboardSettings } from 'hooks/tif/useDashboardSettings'
import { useGroupColorList } from 'hooks/tif/useGroupColorList'
import { useGroups } from 'hooks/tif/useGroups'
import { MarkerLabelStyle, useMarkers } from 'hooks/tif/useMarkers'
import { usePoolingData } from 'hooks/tif/usePoolingData'
import { useRouteLines } from 'hooks/tif/useRouteLines'
import { useRoutes } from 'hooks/tif/useRoutes'
import { useQuery } from 'hooks/useQuery'
import { firebaseContext } from 'providers/FirebaseProvider'

interface Props {}

export const Dashboard: React.FC<Props> = () => {
	/**
	 * URLパラメータ取得
	 */
	const markerLabelStyle = useQuery<MarkerLabelStyle>('markerLabelStyle')

	/**
	 * マーカークリック時のデータ
	 */
	const [selectedGroupDocumentId, setSelectedGroupDocumentId] = useState<string>()
	const [selectedUserDocumentId, setSelectedUserDocumentId] = useState<string>()

	/**
	 * 表示セレクター
	 * チェックボックスの制御用
	 */
	const [groupVisibles, setGroupVisibles] = useState<Record<string, boolean>>({})
	const [routeVisibles, setRouteVisibles] = useState<Record<string, boolean>>({})

	const { db } = useContext(firebaseContext)
	/**
	 * ポーリングで取得される
	 */
	const { groups, groupUsers } = usePoolingData(db, 2500)
	const { groupColors } = useGroupColorList(groups)

	/**
	 * リアルタイム取得される
	 */
	const { groups: realTimeGroups } = useGroups(db)
	const { routes: realTimeRoutes } = useRoutes(db)
	const { settings: realTimeSettings } = useDashboardSettings(db)

	/**
	 * 引数の更新タイミングと同期される（更新頻度が高い）
	 */
	const { markers: realTimeMarkers } = useMarkers(groups, groupUsers, realTimeSettings, groupVisibles, {
		markerLabelStyle
	})

	/**
	 * マージ後のルート情報
	 */
	const { routeLines: realTimeRouteLines } = useRouteLines(realTimeRoutes, routeVisibles)

	/**
	 * エラー用メッセージ
	 */
	const [formErrorMessage, setFormErrorMessage] = useState<string>()

	/**
	 * 準備できたら表示
	 */
	if (realTimeSettings && realTimeMarkers && Object.keys(groups).length > 0 && groupColors) {
		return (
			<Stack>
				<TifMap
					apiKey={import.meta.env.VITE_FIREBASE_APIKEY}
					options={{
						center: {
							lat: realTimeSettings.center.latitude ?? 0,
							lng: realTimeSettings.center.longitude ?? 0
						},
						zoom: realTimeSettings.zoom ?? 0,
						mapId: import.meta.env.VITE_GOOGLE_MAP_ID
					}}
					style={{
						position: 'absolute'
					}}
					markers={realTimeMarkers}
					routes={realTimeRouteLines}
					radius={realTimeSettings.viewAreaRadius}
					start={{
						lat: realTimeSettings.start.latitude,
						lng: realTimeSettings.start.longitude
					}}
					goal={{
						lat: realTimeSettings.goal.latitude,
						lng: realTimeSettings.goal.longitude
					}}
					onClickMarker={(groupDocumentId, userDocumentId) => {
						setSelectedGroupDocumentId(groupDocumentId)
						setSelectedUserDocumentId(userDocumentId)
					}}
				/>
				<SideBar
					style={{
						position: 'fixed',
						right: 0,
						top: 0,
						bottom: 0,
						margin: 0,
						width: 'fit-object',
						minWidth: '300px',
						transition: 'all 1s ease-out',
						padding: '30px',
						overflow: 'scroll',
						background: 'rgba(0,0,0,0.6)'
					}}
				>
					<WindowBox
						title="グループ一覧"
						style={{ maxHeight: '300px', maxWidth: '300px', overflow: 'scroll' }}
					>
						<GroupMarkerController
							groups={realTimeGroups}
							onChangeVisibles={(gv) => {
								setGroupVisibles(gv)
							}}
						/>
					</WindowBox>

					<WindowBox title="メッセージ送信">
						<PushMessageController
							groupUsers={groupUsers}
							groups={groups}
							groupDocumentId={selectedGroupDocumentId}
							userDocumentId={selectedUserDocumentId}
							onError={(message) => {
								setFormErrorMessage(message)
							}}
						/>
					</WindowBox>

					<WindowBox
						title="ルート一覧"
						style={{ maxHeight: '300px', maxWidth: '300px', overflow: 'scroll' }}
					>
						<RouteListController
							routes={realTimeRoutes}
							onChangeVisibles={(rv) => {
								setRouteVisibles(rv)
							}}
						/>
					</WindowBox>
				</SideBar>

				<AlertMessage
					alertMessae={formErrorMessage}
					resetAlertMessage={() => {
						setFormErrorMessage(undefined)
					}}
				/>
			</Stack>
		)
	}

	/**
	 * 準備できてないときはロード画面
	 */
	return (
		<Stack
			sx={{
				position: 'absolute',
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				overflow: 'scroll',
				alignItems: 'center'
			}}
		>
			<CircularProgress />
			<Typography>マップを構成中</Typography>
		</Stack>
	)
}
