import { useEffect, useState } from 'react'
import { Firestore, doc, getDoc } from 'firebase/firestore'
import { useLogger } from 'hooks/useLogger'
import { DashboardSettings } from 'tif/models'

export interface UseDashboardSettings {
	settings?: DashboardSettings
}

export const useDashboardSettings = (db?: Firestore): UseDashboardSettings => {
	const logger = useLogger()
	const [settings, setSettings] = useState<DashboardSettings>()

	useEffect(() => {
		if (!db) return
		const fetch = async (): Promise<void> => {
			const dashboardSettingRef = doc(db, 'settings/dashboard')
			const dashboardSettingSnapshot = await getDoc(dashboardSettingRef)
			const data = dashboardSettingSnapshot.data() as DashboardSettings
			setSettings(data)
		}
		fetch()
	}, [db])

	return {
		settings
	}
}
