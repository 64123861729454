import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Stack } from '@mui/material'
import { firebaseContext } from './FirebaseProvider'

interface Props {
	authFallbackUrl?: string
	children: React.ReactNode
}

export const AuthPageProvider: React.FC<Props> = (props) => {
	const { status } = useContext(firebaseContext)
	const navigaor = useNavigate()

	useEffect(() => {
		if (status === 'failed') {
			navigaor('/login')
		}
	}, [status])

	if (status === 'verify') {
		return (
			<Stack
				sx={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<CircularProgress />
				<Stack>ログイン検証中</Stack>
			</Stack>
		)
	}

	return <>{props.children}</>
}
