import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { routes } from 'configs/routes'
import { Delegate } from 'providers/Delegate'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const router = createBrowserRouter(routes)

root.render(
	<Delegate>
		<RouterProvider router={router} />
	</Delegate>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
