import { useEffect, useRef, useState } from 'react'
import { Unsubscribe } from 'firebase/auth'
import {
	DocumentData,
	DocumentReference,
	Firestore,
	QuerySnapshot,
	collection,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	where
} from 'firebase/firestore'
import { useLogger } from 'hooks/useLogger'
import { Group, User } from 'tif/models'

interface Props {}

type userId = string
type groupId = string

export interface ModelExtension {
	ref: DocumentReference
}

export type Groups = Record<groupId, Group & ModelExtension>
export type Users = Record<userId, User & ModelExtension>
export type GroupUsers = Record<groupId, Users>

export interface UseModelHook {
	groups: Groups
	groupUsers: GroupUsers
}

/**
 * ポーリングしながらデータを取得する
 * @param db Firestore
 * @param interval loop interval
 * @returns
 */
export const usePoolingData = (db?: Firestore, interval: number = 1000): UseModelHook => {
	const logger = useLogger()
	const timer = useRef<NodeJS.Timer>()
	const groupSubscriber = useRef<Unsubscribe>()
	const [groups, setGroups] = useState<Groups>({})
	const [groupUsers, setGroupUsers] = useState<GroupUsers>({})

	/**
	 * グループから情報を返す（非同期）
	 */
	const fetch = async (
		db: Firestore,
		groupRef: DocumentReference
	): Promise<{ groupId: string; group: Group & ModelExtension; users: Users }> => {
		logger.debug('Dashboard', 'getUsersFromGroupRef', '検索開始', groupRef)

		const users: Users = {}

		const usersCollection = collection(db, 'users')
		const q = query(usersCollection, where('groupRef', '==', groupRef))
		const userDocuments = await getDocs(q)
		userDocuments.forEach((document) => {
			const user = document.data() as User & ModelExtension
			user.ref = document.ref
			users[document.id] = user
		})

		const groupDocument = await getDoc(groupRef)
		const group = groupDocument.data() as Group & ModelExtension
		group.ref = groupDocument.ref
		logger.debug('Dashboard', 'getUsersFromGroupRef', '検索完了', users)

		return {
			groupId: groupRef.id,
			users,
			group
		}
	}

	/**
	 * ポーリングタイマーを停止する
	 */
	const stopTimer = (): void => {
		clearInterval(timer.current)
		timer.current = undefined
		logger.debug('Dashboard', 'タイマーの停止')
	}

	/**
	 * ポーリングタイマーを開始する
	 * @param db
	 * @param groupRefs
	 * @param interval
	 * @returns
	 */
	const startTimer = (db: Firestore, groupRefs: DocumentReference[], interval: number = 1000): void => {
		if (timer.current) return

		timer.current = setInterval(() => {
			const update = async (): Promise<void> => {
				const groupUsers: GroupUsers = {}
				const groups: Groups = {}
				const usersPerGroupArray = await Promise.all(
					groupRefs.map(async (groupRef) => await fetch(db, groupRef))
				)

				usersPerGroupArray.forEach((user) => {
					groupUsers[user.groupId] = user.users
					groups[user.groupId] = user.group
				})

				setGroupUsers(groupUsers)
				setGroups(groups)
			}
			update()
		}, interval)

		logger.debug('Dashboard', 'タイマーの開始', interval)
	}

	/**
	 * ポーリングタイマーをリスタートする
	 * @param db
	 * @param snapshot
	 */
	const restartTimer = (db: Firestore, snapshot: QuerySnapshot<DocumentData>): void => {
		if (timer.current) {
			stopTimer()
		}
		const groupRefs: DocumentReference[] = []
		snapshot.docs.forEach((doc) => {
			groupRefs.push(doc.ref)
		})
		startTimer(db, groupRefs, interval)
		logger.debug('Dashboard', 'グループを更新', groups)
	}

	/**
	 * グループコレクションの監視
	 * グループが編集されたらタイマーをリスタートする
	 */
	useEffect(() => {
		if (!db) return
		const groups = collection(db, 'groups')

		if (groupSubscriber.current) {
			groupSubscriber.current()
		}

		// 全グループのリファレンスを取得する
		groupSubscriber.current = onSnapshot(groups, (snapshot) => {
			restartTimer(db, snapshot)
		})
	}, [db, interval])

	useEffect(() => {
		return () => {
			if (groupSubscriber.current) {
				groupSubscriber.current()
			}
			clearInterval(timer.current)
		}
	}, [])

	return {
		groups,
		groupUsers
	}
}
