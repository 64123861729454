import { useEffect, useState } from 'react'
import { Groups } from './usePoolingData'

export interface GroupColor {
	id: string
	name: string
	color: string
	active: boolean
}

export interface UseGroupColorListHook {
	groupColors: GroupColor[]
}

export const useGroupColorList = (groups?: Groups): UseGroupColorListHook => {
	const [groupColors, setGroupColors] = useState<GroupColor[]>([])

	useEffect(() => {
		if (!groups) return
		const gc: GroupColor[] = Object.keys(groups).map((id, index): GroupColor => {
			return {
				id,
				name: groups[id].displayName ?? `グループ${index + 1}`,
				color: groups[id].color ?? 'black',
				active: false
			}
		})
		setGroupColors(gc)
	}, [Object.keys(groups ?? {}).length])

	return {
		groupColors
	}
}
