import { useEffect, useState } from 'react'
import { Firestore, collection, onSnapshot } from '@firebase/firestore'
import { LatLng, Route } from 'tif/map'
import { Route as RouteModel } from 'tif/models'

export interface UseMarkersHooks {
	routes: Record<string, Route>
}

export const useRoutes = (db?: Firestore): UseMarkersHooks => {
	const [_routes, setRoutes] = useState<Record<string, Route>>({})

	useEffect(() => {
		if (!db) return
		const routeRef = collection(db, 'routes')
		const unsubscribe = onSnapshot(routeRef, (snapshot) => {
			const routes: Record<string, Route> = {}
			snapshot.docs.forEach((doc) => {
				const data = doc.data() as RouteModel
				const waypoints: LatLng[] = []
				if (data.waypoints) {
					// キーでソートする
					const sortedWaypoints = Object.entries(data.waypoints).sort((x, y) => {
						return x[0].toUpperCase() > y[0].toUpperCase() ? 1 : -1
					})

					sortedWaypoints.forEach((wp) => {
						const item = wp[1]
						waypoints.push({
							lat: item.latitude,
							lng: item.longitude
						})
					})
				}
				const route: Route = {
					startGeo: {
						lat: data.startGeo?.latitude ?? 0,
						lng: data.startGeo?.longitude ?? 0
					},
					endGeo: {
						lat: data.endGeo?.latitude ?? 0,
						lng: data.endGeo?.longitude ?? 0
					},
					displayName: data.displayName ?? 'Route',
					waypoints,
					strokeColor: data.color ?? '#000',
					strokeOpacity: 0.5,
					strokeWeight: 10,
					visible: data.visible ?? false
				}
				routes[doc.id] = route
			})
			setRoutes(routes)
		})

		return () => {
			unsubscribe()
		}
	}, [db])

	return {
		routes: _routes
	}
}
