import React, { useCallback, useEffect, useState } from 'react'
import { SxProps, FormGroup, FormControlLabel, Checkbox, Stack, Typography } from '@mui/material'
import { Route } from 'tif/map'

interface Props {
	children?: React.ReactNode
	title?: string
	style?: SxProps
	routes: Record<string, Route>
	onChangeVisibles?: (routeVisibles: Record<string, boolean>) => void
}
export const RouteListController: React.FC<Props> = (props) => {
	const [visibles, setVisibles] = useState<Record<string, boolean>>({})

	const onClickCheckbox = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, visible: boolean, groupId: string): void => {
			visibles[groupId] = !visible
			setVisibles({ ...visibles })
		},
		[visibles]
	)

	useEffect(() => {
		if (!props.routes) return
		const gv: Record<string, boolean> = {}

		Object.keys(props.routes).forEach((groupId) => {
			gv[groupId] = true
		})
		setVisibles(gv)
	}, [props.routes])

	useEffect(() => {
		if (props.onChangeVisibles) props.onChangeVisibles(visibles)
	}, [visibles])

	return (
		<Stack padding={2}>
			<FormGroup
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					gridTemplateRows: 'auto'
				}}
			>
				{Object.keys(props.routes).map((id) => {
					const route = props.routes[id]
					return (
						<FormControlLabel
							key={id}
							control={
								<>
									<Checkbox
										checked={visibles[id] ?? false}
										onClick={(event) => {
											onClickCheckbox(event, visibles[id], id)
										}}
										sx={{
											'&.Mui-checked': {
												color: route.strokeColor
											}
										}}
									/>
								</>
							}
							label={<Typography fontSize={'12px'}>{route.displayName}</Typography>}
						/>
					)
				})}
			</FormGroup>
		</Stack>
	)
}
