import { useCallback, useEffect, useState } from 'react'
import { FirebaseApp } from 'firebase/app'
import { Firestore, connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { useLogger } from 'hooks/useLogger'

export interface EmulatorSetting {
	host: string
	port: number
}

/**
 * Firestoreサービスを１つ使用する
 */
export const useSingleFirestore = (
	app?: FirebaseApp,
	emulator?: () => EmulatorSetting | undefined
): Firestore | undefined => {
	const [db, setDb] = useState<Firestore>()
	const logger = useLogger()

	const isInitialized = useCallback(() => {
		return !!db
	}, [db])

	useEffect(() => {
		if (!app) return
		if (isInitialized()) return

		const db = getFirestore(app)
		logger.debug('useSingleFirestore', 'Firestoreの取得', db)
		setDb(db)
		if (!emulator) return
		const setting = emulator()
		if (!setting) return
		connectFirestoreEmulator(db, setting.host, setting.port)
	}, [app])

	return db
}
