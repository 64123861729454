import { createContext } from 'react'
import { Analytics } from 'firebase/analytics'
import { FirebaseApp } from 'firebase/app'
import { Firestore } from 'firebase/firestore'
import { useSingleAnalytics } from 'hooks/google/firebase/useSingleAnalytics'
import { AuthenticationHook, useSingleAuthentication } from 'hooks/google/firebase/useSingleAuthentication'
import { useSingleFirebaseApp } from 'hooks/google/firebase/useSingleFirebaseApp'
import { useSingleFirestore } from 'hooks/google/firebase/useSingleFirestore'

interface Context extends AuthenticationHook {
	app?: FirebaseApp
	db?: Firestore
	analytics?: Analytics
}

const contextDefault: Context = {
	app: undefined,
	db: undefined,
	auth: undefined,
	user: null,
	status: 'verify',
	analytics: undefined,
	setStatus: () => {}
}

export const firebaseContext = createContext<Context>(contextDefault)

interface Props {
	children?: React.ReactNode
}

/**
 * Firebase関連の初期化と提供
 * @param param0
 * @returns
 */
export const FirebaseProvider: React.FC<Props> = ({ children }) => {
	const { app } = useSingleFirebaseApp()
	const analytics = useSingleAnalytics(app)
	const { user, auth, status, setStatus } = useSingleAuthentication(app, () => {
		if (import.meta.env.MODE !== 'docker') return undefined
		return {
			url: import.meta.env.VITE_FIREBASE_AUTHENTICATION_EMULATOR_URL
		}
	})
	const db = useSingleFirestore(app, () => {
		if (import.meta.env.MODE !== 'docker') return undefined
		return {
			host: import.meta.env.VITE_FIREBASE_FIRESTORE_EMULATOR_HOST,
			port: import.meta.env.VITE_FIREBASE_FIRESTORE_EMULATOR_PORT
		}
	})

	const value: Context = {
		app,
		db,
		user,
		auth,
		status,
		setStatus,
		analytics
	}

	return <firebaseContext.Provider value={value}>{children}</firebaseContext.Provider>
}
