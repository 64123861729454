import { useEffect, useState } from 'react'
import { Marker } from 'tif/map'
import { DashboardSettings } from 'tif/models'
import { Groups, GroupUsers } from './usePoolingData'

export type MarkerLabelStyle = 'numeric' | 'nickname' | 'group'

export interface UseMarkersHooks {
	markers: Marker[]
}

interface CircleOptions {
	centerX: number
	centerY: number
	circleRadius: number
}

interface MarkerOptions {
	markerLabelStyle?: MarkerLabelStyle
}

const collisionDetectionCircule = (x: number, y: number, circleOptions: CircleOptions): boolean => {
	const pcx = x - circleOptions.centerX
	const pcy = y - circleOptions.centerY
	const point = pcx * pcx + pcy * pcy
	const r2 = circleOptions.circleRadius ** 2

	return point <= r2
}

export const useMarkers = (
	groups: Groups,
	groupUsers: GroupUsers,
	settings?: DashboardSettings,
	groupVisibles?: Record<string, boolean>,
	options?: MarkerOptions
): UseMarkersHooks => {
	const [markers, setMarkers] = useState<Marker[]>([])

	useEffect(() => {
		if (!settings) return
		const markers = Object.keys(groupUsers)
			.map((groupId) => {
				const users = groupUsers[groupId]
				const group = groups[groupId]
				return Object.keys(users).map((userId, index): Marker => {
					const user = users[userId]
					const visible = groupVisibles ? groupVisibles[groupId] : false
					const artistTile =
						group.displayName && group.displayName.length > 1 ? group.displayName.slice(0, 2) : ''
					const customerTile =
						user.displayName && user.displayName.length > 1 ? user.displayName.slice(0, 2) : ''
					return {
						id: userId,
						groupId,
						kind: user.kind,
						label: ((): string => {
							if (!options?.markerLabelStyle || options.markerLabelStyle === 'numeric') {
								return user.kind === 'customer' ? (index + 1).toString() : artistTile
							}
							if (options.markerLabelStyle === 'nickname') {
								return user.kind === 'customer' ? customerTile : artistTile
							}
							if (options.markerLabelStyle === 'group') {
								return artistTile
							}

							return ''
						})(),
						lat: user.geo?.latitude ?? 0,
						lng: user.geo?.longitude ?? 0,
						color: group.color ?? 'red',
						strokeColor: group.color ?? 'red',
						visible: ((): boolean => {
							if (
								collisionDetectionCircule(user.geo?.latitude ?? 0, user.geo?.longitude ?? 0, {
									centerX: settings.center.latitude,
									centerY: settings.center.longitude,
									circleRadius: settings.viewAreaRadius
								}) &&
								user.status === 'started' &&
								visible
							)
								return true
							return false
						})()
					}
				})
			})
			.flat()
		setMarkers(markers)
	}, [groupUsers, groups, settings, groupVisibles])

	return {
		markers
	}
}
