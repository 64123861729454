import { useCallback, useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Alert, CircularProgress, Slide, Stack, TextField } from '@mui/material'
import { Button } from 'components/common/Button'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { firebaseContext } from 'providers/FirebaseProvider'

interface Inputs {
	email: string
	password: string
}

export const Login: React.FC = () => {
	const { auth, user, status, analytics } = useContext(firebaseContext)
	const [invalidSignIn, setInvalidSignIn] = useState<boolean>(false)
	const navigator = useNavigate()

	useEffect(() => {
		if (!user) return
		if (status === 'successful') {
			navigator('/')
		}
	}, [user, status])

	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: '',
			password: ''
		}
	})

	const onSubmit: SubmitHandler<Inputs> = useCallback(
		async (data: Inputs) => {
			if (!auth) return
			try {
				const user = await signInWithEmailAndPassword(auth, data.email, data.password)
				const token = await user.user.getIdToken(true)
			} catch (err) {
				setInvalidSignIn(true)
				setTimeout(() => {
					setInvalidSignIn(false)
				}, 5000)
			}
		},
		[auth]
	)

	if (status === 'verify') {
		return (
			<Stack
				sx={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<CircularProgress />
				<Stack>ログイン検証中</Stack>
			</Stack>
		)
	}

	return (
		<Stack
			sx={{
				position: 'absolute',
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Slide
				direction="left"
				in={invalidSignIn}
				mountOnEnter
				unmountOnExit
			>
				<Alert
					variant="filled"
					severity="error"
					sx={{
						position: 'absolute',
						width: '100%',
						top: 0
					}}
				>
					ログインに失敗しました
				</Alert>
			</Slide>
			<Stack
				component="form"
				noValidate
				spacing={1}
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					width: '80vw',
					maxWidth: '320px'
				}}
			>
				<Stack
					sx={{
						textAlign: 'center',
						backgroundColor: 'yellow',
						padding: '10px'
					}}
				>
					<img
						src="title.png"
						style={{
							backgroundSize: 'cover'
						}}
					/>
				</Stack>
				<Controller
					control={control}
					name="email"
					render={({ field }) => {
						return (
							<TextField
								{...field}
								label="email"
								type="text"
								placeholder="email"
							></TextField>
						)
					}}
				/>
				<Controller
					control={control}
					name="password"
					render={({ field }) => {
						return (
							<TextField
								{...field}
								label="password"
								type="password"
								placeholder="password"
							></TextField>
						)
					}}
				/>
				<Button
					variant="contained"
					type="submit"
				>
					Login
				</Button>
			</Stack>
		</Stack>
	)
}
