import { useCallback, useEffect, useRef, useState } from 'react'
import { FirebaseApp } from 'firebase/app'
import { Auth, User, connectAuthEmulator, getAuth, onAuthStateChanged } from 'firebase/auth'
import { useLogger } from 'hooks/useLogger'

export interface EmulatorSetting {
	url: string
}

export interface AuthenticationHook {
	user: User | null
	auth?: Auth
	status: 'verify' | 'successful' | 'failed'
	setStatus: React.Dispatch<React.SetStateAction<'verify' | 'successful' | 'failed'>>
}

/**
 * Authenticationサービスインスタンスを１つ使用する
 * @param app
 * @returns
 */
export const useSingleAuthentication = (
	app?: FirebaseApp,
	emulator?: () => EmulatorSetting | undefined
): AuthenticationHook => {
	const [user, setUser] = useState<User | null>(null)
	const [auth, setAuth] = useState<Auth>()
	const [status, setStatus] = useState<'verify' | 'successful' | 'failed'>('verify')
	const timer = useRef<NodeJS.Timer>()

	const logger = useLogger()

	const isInitialized = useCallback(() => {
		return !!auth
	}, [auth])

	useEffect(() => {
		if (!user) return
		if (timer.current) {
			clearInterval(timer.current)
		}

		timer.current = setInterval(() => {
			user.getIdToken(true).then((token) => {
				logger.info('useSingleAuthentication', 'トークンをリフレッシュしました', token)
			})
		}, 60 * 50 * 1000)
	}, [user])

	useEffect(() => {
		if (!app) return
		if (isInitialized()) return
		const auth = getAuth(app)

		if (emulator) {
			const setting = emulator()
			if (setting) {
				connectAuthEmulator(auth, setting.url)
			}
		}

		const unsubscribed = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user)
				setStatus('successful')
				user.getIdToken(true).then((token) => {
					logger.info('useSingleAuthentication', 'トークンをリフレッシュしました', token)
				})
				logger.info('useSingleAuthentication', 'ログイン済みです', user)
			} else {
				setUser(null)
				setStatus('failed')
			}
		})

		logger.info('useSingleAuthentication', 'ログイン認証確認を開始しました')

		setAuth(auth)

		return () => {
			unsubscribed()
		}
	}, [app])

	return { auth, user, status, setStatus }
}
