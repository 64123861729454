// MapMarkerの画像のみを使用するためのコンポネント
export const SideBarMarkerIcon = (color?: string, strokeColor?: string): string => `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 46.79">
      <defs>
        <style>
          .cls-1 {
            fill: ${color ?? 'blue'};
            stroke: ${strokeColor ?? 'blue'};
            stroke-linejoin: round;
            stroke-width: 2px;
          }
        </style>
        
      </defs>
      <g id="_一覧_メッセージ" data-name="一覧／メッセージ">
        <path class="cls-1" d="m41,21c0-11.05-8.95-20-20-20S1,9.95,1,21c0,9.64,6.82,17.68,15.89,19.57l4.11,6.21,4.11-6.21c9.08-1.89,15.89-9.94,15.89-19.57Z"/>
      </g>
    </svg>
  `

export const sideBarSvgToBase64DataURL = (
	svg: string,
	size: number
): {
	url: string
	scaledSize: number
} => {
	return { url: `data:image/svg+xml,${encodeURIComponent(svg)}`, scaledSize: 1 }
}
