import { useEffect, useState } from 'react'
import { Firestore, collection, onSnapshot } from '@firebase/firestore'
import { Groups } from './usePoolingData'

export interface UseGroupsHook {
	groups: Groups
}

export const useGroups = (db?: Firestore): UseGroupsHook => {
	const [groups, setGroups] = useState<Groups>({})

	useEffect(() => {
		if (!db) return
		const groupsRef = collection(db, 'groups')
		const unsubscribe = onSnapshot(groupsRef, (snapshot) => {
			const groups: Groups = {}
			snapshot.docs.forEach((doc) => {
				groups[doc.id] = {
					...doc.data(),
					ref: doc.ref
				}
			})
			setGroups(groups)
		})

		return () => {
			unsubscribe()
		}
	}, [db])

	return {
		groups
	}
}
