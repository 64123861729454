import { useRef, useState } from 'react'
import axios, { AxiosInstance } from 'axios'

interface UseSendPushNotificationHook {
	post: <REQ, RES>(path: string, request: REQ) => Promise<RES | undefined>
	setError: React.Dispatch<React.SetStateAction<Error | undefined>>
	error?: Error
}

export const useApi = (): UseSendPushNotificationHook => {
	const [error, setError] = useState<Error>()
	const api = useRef<AxiosInstance>(
		axios.create({
			baseURL: import.meta.env.VITE_API_BASE_URL,
			headers: {
				'X-API-KEY': import.meta.env.VITE_API_KEY
			}
		})
	)

	const post = async <REQ, RES>(path: string, request: REQ): Promise<RES | undefined> => {
		try {
			const { data } = await api.current.post<RES>(path, request)
			return data
		} catch (error) {
			if (error instanceof Error) {
				setError(error)
			}
		}
	}

	return {
		post,
		error,
		setError
	}
}
