import { useEffect, useRef } from 'react'
import { ThemeProvider, createTheme } from '@mui/material'
import { useLogger } from 'hooks/useLogger'
import { FirebaseProvider } from './FirebaseProvider'

interface Props {
	children?: React.ReactNode
}

export const Delegate: React.FC<Props> = (props) => {
	const logger = useLogger()
	const theme = useRef(
		createTheme({
			palette: {
				primary: {
					// Purple and green play nicely together.
					main: '#FF3399'
				},
				secondary: {
					main: '#FF3122'
				}
			}
		})
	)

	useEffect(() => {
		logger.info(`実行モード: ${import.meta.env.MODE}`)
	}, [])

	return (
		<FirebaseProvider>
			<ThemeProvider theme={theme.current}>{props.children}</ThemeProvider>
		</FirebaseProvider>
	)
}
