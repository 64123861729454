import { useCallback, useEffect, useState } from 'react'
import { Analytics, getAnalytics } from 'firebase/analytics'
import { FirebaseApp } from 'firebase/app'
import { useLogger } from 'hooks/useLogger'

/**
 * Firestoreサービスを１つ使用する
 */
export const useSingleAnalytics = (app?: FirebaseApp): Analytics | undefined => {
	const [analytics, setAnalytics] = useState<Analytics>()
	const logger = useLogger()

	const isInitialized = useCallback(() => {
		return !!analytics
	}, [analytics])

	useEffect(() => {
		if (!app) return
		if (isInitialized()) return

		const analytics = getAnalytics(app)

		logger.debug('useSingleAnalytics', 'analyticsの取得', analytics)
		setAnalytics(analytics)
	}, [app])

	return analytics
}
