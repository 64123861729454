import { RouteObject } from 'react-router-dom'
import { Dashboard } from 'pages/Dashboard'
import { Login } from 'pages/Login'
import { AuthPageProvider } from 'providers/AuthPageProvider'

type RouteConfig = {
	title?: string
} & RouteObject

export const routes: RouteConfig[] = [
	{
		path: '/',
		element: (
			<AuthPageProvider>
				<Dashboard />
			</AuthPageProvider>
		)
	},
	{
		path: '/login',
		element: <Login />
	}
]
