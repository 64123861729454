import { useEffect, useState } from 'react'
import { Route } from 'tif/map'

export interface UseMarkersHooks {
	routeLines: Route[]
}

export const useRouteLines = (
	routes: Record<string, Route>,
	routeVisibles?: Record<string, boolean>
): UseMarkersHooks => {
	const [routeLines, setRouteLines] = useState<Route[]>([])

	useEffect(() => {
		const markers = Object.keys(routes).map((routeId) => {
			const visible = routeVisibles ? routeVisibles[routeId] : false
			const route: Route = {
				...routes[routeId],
				visible
			}
			return route
		})
		setRouteLines(markers)
	}, [routes, routeVisibles])

	return {
		routeLines
	}
}
